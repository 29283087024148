import { createAction, props } from '@ngrx/store';
import { PricesPerLocationMapped } from './../../models/price.model';
import { contractmanagement_api } from '@gridscale/gs-services';

export const actionPrefix = '[prices-per-location]';

export const loadPricesPerLocation = createAction(actionPrefix + ' Load Prices per location');

export const loadPricesPerLocationSuccess = createAction(actionPrefix + ' Load Prices per location Success', props<{
  result: PricesPerLocationMapped,
  rawResult: contractmanagement_api.PricesResponse
}>());

export const loadPricesPerLocationFailure = createAction(actionPrefix + ' Load Prices per location Failure', props<{ error: Error }>());
