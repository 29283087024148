import { Price, PricesPerLocationGetResponse, PricesPerLocationMapped } from './../../models/price.model';
import { createReducer, on } from '@ngrx/store';
import * as PricesPerLocationActions from './prices-per-location.actions';
import { contractmanagement_api } from '@gridscale/gs-services';

export const featureKey = 'prices-per-location';

export interface State extends PricesPerLocationMapped {
  loading: boolean;
  loaded: boolean;
  error?: Error;
  rawResult?: contractmanagement_api.PricesResponse
}

export const initialState: State = {
  loading: false,
  loaded: false,
  locations: [],
  rawResult: undefined
};

export const reducer = createReducer(
  initialState,

  on(PricesPerLocationActions.loadPricesPerLocation, (state, action) => ({
    ...state,
    loading: true
  })),

  on(PricesPerLocationActions.loadPricesPerLocationSuccess, (state, action) => ({
    ...state,
    loading: false,
    loaded: true,
    locations: action.result.locations,
    rawResult: action.rawResult,
    error: undefined
  })),

  on(PricesPerLocationActions.loadPricesPerLocationFailure, (state, action) => ({
    ...state,
    loading: false,
    loaded: true,
    error: action.error
  }))
);
